import {ref} from 'vue'
import moment from 'moment'
import {axiosInstance as axios} from '@/plugins/axios.js'
import VueToastr from 'vue-toastr'
import Swal from 'sweetalert2'
import {useModal} from '@/composables/core/modal'


const { closeModal } = useModal()
const shifts = ref([])
const form = ref({
	start_time: '',
	end_time: ''
})

const resetForm =  () => {
	form.value.start_time = ''
	form.value.end_time = ''
}

const formatWorkShiftData = (workShiftArray) => {
	const newTableData = []
	workShiftArray.forEach((eachShift) => {
	  newTableData.push({
		id: eachShift.id,
		start_time: moment(eachShift.start_time, 'HH:mm').format('h:mm A'),
		end_time: moment(eachShift.end_time, 'HH:mm').format('h:mm A')
	  })
	})

	return newTableData
}

export const useShifts = () => {
	const processing = ref(false)
	const addShift = (companyId, toastr) => {
		processing.value = true
		const payload = {
			startTime: moment(form.value.start_time, 'h:mm A').format('HH:mm'),
			endTime: moment(form.value.end_time, 'h:mm A').format('HH:mm'),
			description: `${moment(form.value.start_time, 'h:mm A').format(
				'ha'
			)} to ${moment(form.value.end_time, 'h:mm A').format('ha')} shift`
		}
		axios
		.post(`/v1/corporates/${companyId}/work-shifts`, payload)
		.then(() => {
			toastr.s('Work-shift saved successfully!')
			resetForm()
			closeModal()
			fetchWorkShifts(companyId)
		})
		.catch((err) => {
			toastr.e(err?.response?.data?.message, 'Error')
		})
		.finally(() => {
			processing.value = false
		})
	}
	const fetchWorkShifts = (companyId) => {
		processing.value = true
		axios.get(`/v1/corporates/${companyId}/work-shifts`)
        .then((res) => {
			if(res.data.data) {
				shifts.value = formatWorkShiftData(res.data.data)
			} else {
				shifts.value = []
			}
			
        })
        .catch((error) => {
        //   this.$toastr.e(error?.response?.data?.message, 'Error')
			Swal.fire({ icon: 'error', title: 'Error', text: error?.response?.data?.message})
        })
        .finally(() => {
			processing.value = false
        })
	}
	const deleteWorkShift = async (companyId, work_shift, swal) => {
		await swal({
			icon: 'question',
			title: 'Please Confirm',
			text: 'Are you sure you want to delete this work shift?',
			showConfirmButton: true,
			showCancelButton: true,
			preConfirm: () => {
			  return axios
				.delete(
				  `v1/corporates/${companyId}/work-shifts/${work_shift.id}`
				)
				.catch((error) => {
				  const msg =
					error.response && error.response.data
					  ? error.response.data.message
					  : 'An error occured, please try again.'
	
				  swal().showValidationMessage(msg)
				})
				.finally(() => {
				//   this.loadCorporateWorkShift()
					fetchWorkShifts(companyId)
				})
			},
			allowOutsideClick: () => !Swal.isLoading()
		  }).then((result) => {
			if (result.isConfirmed) {
			  swal({
				icon: 'success',
				title: 'Success',
				text: '\'Work shift was successfully deleted',
				showCloseButton: true
			  })
			}
		  })
	}
	return { processing, addShift, form, shifts, fetchWorkShifts, deleteWorkShift, resetForm}
}

export const useUpdateShift = () => {
	const processing = ref(false)
	const updateShift = (companyId, selected_workShiftId, toastr) => {
		processing.value = true
		const payload = {
			startTime: moment(form.value.start_time, 'h:mm A').format('HH:mm'),
			endTime: moment(form.value.end_time, 'h:mm A').format('HH:mm'),
			description: `${moment(form.value.start_time, 'h:mm A').format(
			  'ha'
			)} to ${moment(form.value.end_time, 'h:mm A').format('ha')} shift`
		}
		axios
		.patch(
			`/v1/corporates/${companyId}/work-shifts/${selected_workShiftId}`,
			payload
		)
		.then(() => {
			toastr.s('Work shift was successfully updated ', 'Success')
			closeModal()
			useShifts().fetchWorkShifts(companyId)
		})
		.catch((error) => {
			toastr.e(error?.response?.data?.message, 'Error')
		})
		.finally(() => {
			processing.value = false
		})
	}

	return { form, processing, updateShift }
}