<template>
	<modalLayout title="Edit Work Shift">
		<form @submit.prevent="updateShift(companyId,shiftId, $toastr)">
			<div class="row">
				<div class="col-12">
					<div class="col-12">
						<div class="form-group">
							<label class="form-label"> Start time </label>
							<div>
								<vue-timepicker auto-scroll hide-clear-button v-model="form.start_time" format="h:mm A" style="width: 100%"></vue-timepicker>
							</div>
						</div>
					</div>
					<div class="col-12">
						<div class="form-group">
							<label class="form-label"> End time</label>
							<div>
								<vue-timepicker auto-scroll hide-clear-button v-model="form.end_time" format="h:mm A" style="width: 100%"></vue-timepicker>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="w-100 mt-[60px]">
				<button  :disabled="processing" type="submit" class="btn btn-primary w-100">
					{{ processing ? 'Processing...' : 'Update work shift' }}
				</button>
			</div>
		</form>
	</modalLayout>
</template>

<script setup>
import {defineProps} from 'vue'
import modalLayout from '@/layouts/core/modal.vue'
import VueTimepicker from 'vue2-timepicker'
import moment from 'moment'
import {ref, computed, onMounted} from 'vue'
import {useUpdateShift} from '@/composables/backend/companies/workshift'

const { processing, form, updateShift } = useUpdateShift()
const props = defineProps(['modal_prop'])
const shiftId = ref()
const companyId = ref()

onMounted(() => {
	form.value.start_time = props.modal_prop.start_time
	form.value.end_time = props.modal_prop.end_time
	shiftId.value = props.modal_prop.shiftId
	companyId.value = props.modal_prop.companyId
})

</script>
